.news-block__title {
	padding: 3px 0;

	& > a {
		font-size: 16px;
		text-transform: uppercase;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}
}


.news-block__text {
	font-size: 14px;
	margin: 0.5em 0;
	line-height: 1.333;
	text-align: justify;
}


.news-block__date {
	text-align: right;
	vertical-align: middle;
	width: 85px;
	padding-bottom:4px;

	& > span {
		background: $linkColor;
		color: #fff;
		font-size: 13px;
		padding: 0 8px 1px;
		white-space: nowrap;

		&.news-publish {
			background: none;
			display: block;
			color: $textColor;
			font-size: 10px;
			padding: 0;
		}
	}
}




/* Новости на главной     */
/* ...в левой колонке     */
.news-block {

	&__item {
		margin: 0 0 1em;
		border-bottom: 1px solid #ccc;

		@include media(md){
			margin: 0 10px 1em;
		}
	}


	&__header {
		display: table;
		width: 100%;
	}


	&__date,
	&__title {
		display: table-cell;
	}


	&__date {
		width: 68px;
	}


	&__image {
		@extend .img-responsive;
	}


	.readmore-link {
		display: block;
	}

	.news-all {
		margin: 7px 0 0;
		text-align: center;

		& > a {
			font-size: 19px;
			text-transform: uppercase;
		}
	}

	@include media(xmd) {
		.news {
			width: calc(50% - 1em);

			&:last-child {
				width: 100%;
			}
		}
	}

	@include media(sm) {
		.news {
			width: 100%;
		}
	}
}


/* Список новостей - news/page/1 */
.news-list {
	@extend .clearfix;
}


.news-item {
	@extend .clearfix;

	float: left;
	margin: 0 3em 2em 0;
	padding: 0 0 1em;
	width: calc(50% - 1.5em);


	&:nth-child(2n + 2) {
		margin: 0 0 2em;
	}

	@include media(xlg) {
		margin: 0 1em 2em 0;
		width: calc(50% - 0.5em);
	}

	@include media(xmd) {
		margin: 0 0 2em;
		width: 100%;
	}

	&__image {
		display: block;
		height: auto;
		max-width: 600px;
		width: 100%;
	}

	&__header {
		padding: 1em 1em 0.25em;
	}


	&__title {
		padding-bottom: 20px;
		
		& > a {
			color: $black;
			display: inline-block;
			font-size: 24px;
			font-weight: normal;
			line-height: 1.2;
		}
	}
	
	&__info {
		color: $red;
		font-size: 13px;
	}


	&__text {
		font-size: 14px;
		line-height: 1.666;
		padding: 0.25em 1em;
	}

	&__bottom {
		@extend .clearfix;

		padding: 1em;

		.pluso {
			float: right;
		}
		
		.readmore-link {
			float: left;
		}
	}
}


.last-news {
	@extend .clearfix;
	padding: 0.5em;

	&__item {
		padding: 0.5em;
		position: relative;

		& > a {
			display: block;
		}

		&.start {
			padding: 2em 2em 1em;
			width: 43%;
			margin-right: 7%;

			& > .html {
				* {
					font-size: $fontSizeBase;
				}
			}

			@include media(1560px) {
				width: 47%;
				margin-right: 3%;

				& > .html {
					* {
						font-size: calc(#{$fontSizeBase} + 0.3vw - 5px);
					}
				}
			}
			@include media(xlg) {
				width: 50%;
				margin-right: 0;
			}

			@include media(lg) {

				& > .html {
					* {
						font-size: 14px;
					}
				}
			}
		}

		@include media(lg) {

			&.start {

			}

			&.col-3 {
				width: 50%;
			}

			&.col-6 {
				width: 100%;
			}
		}

		@include media(xmd) {

			&.start {

			}

			&.col-3 {
				width: 100%;
			}

			&.col-6 {
				width: 100%;
			}
		}
	}

	&__head {
		& > h2 {
			color: $black;
			font-size: 48px;
			font-weight: bold;

			& > span {
				color: $linkColor;
			}
		}

		.title {
			color: $black;
			font-size: 28px;
			line-height: 1.2;
			margin-bottom: 10px;
		}

		@include media(1560px) {

			& > h2 {
				font-size: calc(48px + 3vw - 48px);
				padding-bottom: calc(1em + 28vw - 10em);
			}
			.title {
				font-size: calc(28px + 1vw - 20px);
			}
		}
		@include media(lg) {

			& > h2 {
				font-size: 28px;
				padding-bottom: 10px;
			}
			.title {
				font-size: 18px;
			}
		}
	}

	&__header {
		border-bottom: 1px solid #fff;
		pointer-events: none;
		position: absolute;
		top: 0.5em;
		left: 0.5em;
		right: 0.5em;
		bottom: 0.5em;
		margin: 0 0 3em;
	}

	&__title {
		padding: 0 1em;
		top: 50%;
		position: relative;
		transform: translateY(-50%);

		& > a {
			display: block;
			font-weight: normal;
			color: $white;
			font-size: 28px;
			line-height: 1.1;
			pointer-events: all;
			text-shadow: 1px 1px 1px rgba(0,0,0,0.3);

			@include media(1560px) {
				font-size: calc(28px + 0.7vw - 15px);
			}
			@include media(lg) {
				font-size: 20px;
			}
		}
	}

	&__info {
		padding: 0 2em;
		position: absolute;
		height: 3em;
		bottom: 0.5em;
		left: 0;
		right: 0;

		& > span {
			color: #fff;
			font-size: 13px;
			margin: 1em 0 0;
		}
	}

	&__date {
		float: left;
		white-space: nowrap;
	}

	&__author {
		float: right;
		white-space: nowrap;
	}

	&__image {
		display: block;
		height: auto;
		max-width: 100%;
		width: 100%;
	}

	.all-news {
		margin: 1em 0 0;

		& > a {
			color: $black;
			font-weight: bold;
			font-size: 16px;
			letter-spacing: 3px;
			text-transform: uppercase;

			@include media(lg) {
				font-size: 14px;
			}
		}
	}
}

.no-mobile {
	.last-news {

		&__item {
			& > a {
				&:hover {
					& + .last-news__header {
						a {
							color: $linkColor;
						}
					}
				}
			}
		}


		.all-news {
			& > a {
				&:hover {
					color: $linkColor;
				}
			}
		}
		
		&__title {
			& > a {
				&:hover {
					color: $linkColor;
				}
			}
		}
	}
	
	.news-item {
		&__title {
			& > a {
				&:hover {
					color: $linkColor;
				}
			}
		}
	}
}

