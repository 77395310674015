// Стили слайдера на главной
.slider-block {
	height: 462px;
	position: relative;
	width: 100%;

	@include media(md) {
		height: 380px;
	}

	.search {
		padding: 66px 0 50px;
		position: absolute;
		top: 0;
		left: 50%;
		height: 100%;
		margin-left: -332px;
		width: 664px;
		z-index: 1;

		@include media(lg) {
			margin-left: -250px;
			width: 500px;
		}

		@include media(md) {
			padding: 38px 0 50px;
		}

		@include media(xmd) {
			margin-left: 0;
			left: 3em;
			right: 3em;
			width: auto;
		}
	}



	.slider,
	.slick-list,
	.slick-track {
		height: 100%;
	}


	.slick-dots {
		position: absolute;
		bottom: 5px;
		list-style: none;
		display: block;
		text-align: center;
		padding: 0;
		margin: 0;
		width: 100%;
		z-index: 2;

		& > li {
			display: inline-block;
			font-size: 0;
			padding: 0;
			height: 35px;
			width: 35px;

			& > button {
				background: transparent;
				border-radius: 50%;
				cursor: pointer;
				font-size: 0;
				padding: 0;
				position: relative;
				height: 35px;
				width: 35px;
				text-indent: -9999px;
				-webkit-tap-highlight-color: $webkitHighlightTapColor;

				&:before {
					background: #c9c5be;
					border-radius: 50%;
					box-shadow: none;
					font-size: 0;
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					margin: -4px 0 0 -4px;
					height: 9px;
					width: 9px;
				}
			}

			&.slick-active {

				& > button {
					-webkit-tap-highlight-color: transparent;

					&:before {
						//background: #9d9d9d;
						box-shadow: 0 0 0 3px #9d9d9d;
					}
				}
			}
		}
	}

	.slick-arrow {
		height: 58px;
		opacity: 0.6;
		margin-top: -31px;
		width: 44px;

		&:hover {
			background: transparent;
			opacity: 1;
			transition: 0.3s ease-out;
		}

		&:before {
			font: normal 94px/63px "FontAwesome";
			color: #fff;
		}

		@include media(lg) {
			height: 36px;
			margin-top: -18px;
			width: 36px;

			&:before {
				font-size: 48px;
				line-height: 36px;
			}
		}
	}

	.slick-prev {
		left: calc(((100% - 1200px) / 2) + 1em);

		&:before {
			content: '\f104';
			margin: -34px 0 0 -22px;

			@include media(lg) {
				margin: -20px 0 0 -11px;
			}
		}
	}

	.slick-next {
		right: calc(((100% - 1200px) / 2) + 1em);

		&:before {
			content: '\f105';
			margin: -34px 0 0 -11px;

			@include media(lg) {
				margin: -20px 0 0 -8px;
			}
		}
	}

	@include media(xlg) {

		.slick-next {
			right: 1em;
		}

		.slick-prev {
			left: 1em;
		}
	}

	@include media(md) {

		.slick-next {
			right: 0;
		}

		.slick-prev {
			left: 0;
		}
	}
}



.slider-item {

	&__wrapper {
		padding: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		& > div {
			height: 100%;
			margin: 0 auto;
			max-width: $containerWidth;
			position: relative;
			width: 91%;
		}
	}

	&__label {
		overflow: hidden;
		padding: 3em 0;
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		opacity: 0;
		transform: translateY(-20%);
		text-align: center;

		& > h1,
		& > h2 {
			color: $white;
			display: inline-block;
			font-size: 48px;
			font-weight: normal;
			letter-spacing: 2px;
			position: relative;
			padding: 0 0.5em;
			user-select: none;

			&:before {
				content: '';
				position: absolute;
				background: $white;
				left: 100%;
				top: 58%;
				height: 1px;
				width: 400%;
			}

			&:after {
				content: '';
				position: absolute;
				background: $white;
				right: 100%;
				top: 58%;
				height: 1px;
				width: 400%;
			}

			@include media(xlg) {
				font-size: calc(48px + 3.5vw - 47px);
			}
		}

		.description {
			border-bottom: 1px solid $white;
			color: $white;
			font-size: 99px;
			font-weight: bold;
			line-height: 1.1;
			letter-spacing: 3px;
			padding: 0.333em 0 0.75em;
			margin: 0 0 1em;
			text-transform: uppercase;
			user-select: none;

			@include media(xlg) {
				font-size: calc(99px + 7vw - 90px);
			}
		}

		.button-block {
			text-align: center;

			& > .button {
				display: inline-block;
			}
		}
	}

	&.slick-active {
		.slider-item__label {
			opacity: 1;
			transition: 0.7s ease-out 0.5s;
			transform: translateY(-50%);
		}
	}
}


