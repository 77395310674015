nav {
	background: #422026;
	width: 100%;


	.arrow {
		display: block;
		position: absolute;
		height: 20px;
		margin-top: -12px;
		width: 18px;
		right: 3px;
		top: 50%;
		pointer-events: none;

		@include fontawesome('\f0d7') {
			color: $white;
			font-size: 14px;
			line-height: 14px;
			margin: -6px 0 0 -4px;
			transition: 0.3s ease-out;
		}
	}

	.menu_level_1 {
		display: block;
		padding: 0;
		list-style: none;
		margin: 0 auto;
		max-width: $containerWidth;
		width: 100%;

		& > li {
			display: table-cell;
			position: relative;
			text-align: center;
			vertical-align: middle;

			&.has_child {
				& > a {
					padding: 5px 23px 7px 8px;
				}
			}
			
			& > a {
				color: $white;
				display: inline-block;
				font-size: 14px;
				padding: 5px 8px 7px;
				line-height: 1.333;
				min-height: 40px;
				text-decoration: none;
				text-align: center;
				vertical-align: middle;
			}


			&.active {
				cursor: default;
				
				& > a {
					background: $red;
				}
			}

		}

		a {
			display: block;
			text-decoration: none;
		}


		&.deep-3,
		&.deep-2 {
			& > li {
				&.selected {
					& > a {
						color: $linkColor;
						
						&:hover {
							color: $linkColor + 70%;
						}
					}
				}
			}
		}


		&.deep-3 {
			.menu_level_2 {
				& > li {
					&.active {
						& > a {
							color: $linkColor--hover;

							&:hover {
								color: $linkColor--hover - 50%;
							}
						}
					}
				}
			}
		}

	}

	/* Всплывающее меню */
	.menu_level_2 {
		background: #fff;
		box-shadow: 3px 3px 10px rgba(0,0,0,0.15);
		display: table;
		list-style: none;
		margin: 0;
		min-width: 230px;
		padding: 0;
		position: absolute;
		opacity: 0;
		top: 100%;
		left: 0;
		z-index: 999;
		text-align: left;
		text-transform: none;
		visibility: hidden;

		& > li {
			display: table-cell;

			& > a {
				color: $black;
				display: block;
				font-size: 14px;
				padding: 4px 1em;
			}
		}
	}

	.menu_level_3 {
		display: block;
		list-style: none;

		& > li {
			& > a {
				color: $textColor - 50%;
				display: block;
			}

			&.active {
				& > a {
					color: $linkColor--hover;
				}
			}
		}
	}
}

.flexbox {
	nav {
		.menu_level_1 {
			& > li {
				& > a {
					align-items: center;
					display: flex;
				}
			}
		}
	}
}

.no-mobile {

	nav {
		.menu_level_1 {

			& > li {

				/* Показываем всплывашку на ховер */
				&.has_child {
					&:hover {

						& > a {
							& + .arrow {
								&:before {
									transform: translateY(3px);
								}
							}
						}

						.menu_level_2 {
							opacity: 1;
							visibility: visible;
							transform: translate3d(0, 0, 0);
						}
					}
				}
				
				&:not(.active) {
					
					&:hover {
						& > a {
							background: #5a333a;
						}
					}
				}
			}
		}

		.menu_level_2 {
			display: block;
			left: 0;
			transition: 0.3s ease-out;
			transform-origin: center;
			transform: translate3d(0, 1em, 0);

			& > li {
				display: block;
				width: 100%;
				
				&.active {
					& > a {
						color: $linkColor--hover;
					}
				}
				
				&:not(.active) {
					& > a {
						&:hover {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.menu_level_3 {
			& > li {
				&:not(.active) {
					&:hover {
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}
	}
}
