@import "vars";


.footer-top {

	.created-by {
		color: #a3a7ab;
		font-size: 13px;
		display: inline-block;
		line-height: 1.333;
		text-align: left;
		vertical-align: middle;

		& > span {
			color: #fff;
			display: block;
			text-transform: uppercase;
		}
	}

	.created {

		& > a {
			display: block;

			& > img {
				display: inline-block;
				height: auto;
				margin: 0 10px 0 0;
				max-width: 65px;
				vertical-align: middle;
				width: 100%;
			}
		}
	}
}


.footer-bottom {
	background: $footerBottomBgColor;
	
	.copyright {
		& > span {
			color: $white;
			display: inline-block;
			font-size: 14px;
			line-height: 1.2;
		}
	}
	

	@include media(md) {
		.col-4,
		.col-8 {
			width: 100%;
		}
	}
}


/* Оформление */
.footer-top {
	background: $footerTopBgColor;
	padding: 2em 0;
}


.footer-menu {
	display: block;
	list-style: none;

	&__title {
		font-size: 14px;
		font-weight: bold;
		color: $red;
		margin: 0 0 6px;
		text-transform: uppercase;
	}

	&__wrapper {

		@include media(md) {
			margin: 0 0 2em;
		}
	}

	&__item {
		margin: 0 0 8px;
		padding: 0 0 0 1em;
		position: relative;
		
		&.active {
			&:before {
				border-left-color: $red;
			}
		}

		&:before {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-top: 4px solid transparent;
			border-left: 4px solid #fff;
			border-bottom: 4px solid transparent;
			position: absolute;
			left: 0;
			top: 6px;
			z-index: 2;
		}

		& > span {
			color: $red;
			cursor: default;
			display: block;
			font-size: 13px;
			line-height: 1.45;
		}

		& > a {
			color: $white;
			display: block;
			font-size: 13px;
			line-height: 1.45;
			position: relative;
			text-decoration: underline;
		}
	}
}


.no-mobile {
	.footer-menu {
		&__item {
			&:hover {
				
				&:before {
					border-left-color: $red;
				}
				
				& > a {
					color: $red;
				}
			}
		}
	}
}


.footer-bottom {
	background: $footerBottomBgColor;
	padding: 0.5em 0;

}

