@import "../system/icon";


.header__logo {
	float: left;
	padding: 0 1em;
	width: 140px;

	.logo-img {
		display: block;
		height: auto;
		max-width: 110px;
		width: 100%;
	}

	& > a {
		display: block;
	}

	&-text {
		border-left: 1px solid #aaaaaa;
		float: left;
		padding: 0 1em;
		width: 240px;

		& > span {
			color: #686868;
			display: inline-block;
			font-size: 13px;
			line-height: 1.2;
		}
	}

	@include media(sm) {
		padding: 8px 0.5em 0 1em;
		width: 80px;


		&-text {

			padding: 0 0 0 0.5em;
			width: 186px;

			& > span {
				font-size: 11px;
			}
		}
	}
}


header {
	padding: 24px 0 16px;

	@include media(sm) {
		padding: 0.5em 0;
	}
}


