$containerWidth: 1200px;
$mobileFixedHeaderHeight: 44px;
$desktopFixedHeaderHeight: 30px;

$isAdaptive: true;

$fontName: "SegoeUI";
$fontSizeBase: 15px;

$white: #fff;
$black: #222;

$linkColor: #ae2600;             /* Цвет ссылок */
$linkColor--hover: #f6846a;      /* Цвет ссылок при наведении */
$linkColor--hover2: #f6846a;

$webkitHighlightTapColor: rgba(0,0,0, 0.15);

$textColor: #7f8187;                           /* Цвет текста */
$red: #ae2600;
$grey: #666;
$pink: #d9017f;
$green: #9ed178;
$lightGreen: #9ed178;
$violet: #cf98de;
$sand: #f6846a;
$transSand: rgba(246,132,106,0.8);
$lightGrey: #f2f2f2;
$transGrey: rgba(255,255,255,0.8);
$blue: #67b0d6;
$lightBlue: #bae4f9;

$iconColor: $linkColor--hover;


$bgColor: #f2f2f2;                        /* Цвет фона */
$headerTopBgColor: #f2f2f2;               /* Цвет фона верхней шапки */
$headerTopBgColorMobile: #fff;         /* Цвет фона верхней шапки на мобильных */
$headerBottomBgColor: #fff;            /* Цвет фона нижней шапки  */


$footerTopBgColor: #41252a;
$footerBottomBgColor: #341b1f;
$footerLinkColor: #fff;



/* Цвета ошибок */
$colorError: rgba(253, 31, 36, 0.7);

/* Цвета ОК */
$colorOk: rgba(92, 253, 5, 0.7);



/* Borders */
$borderColor: #ccc;
$borderRadius: 4px;               /* Закругления */
$photoBorderRadius: 8px;
$border: 1px solid $borderColor;

/* Вертикальный градиент вверх */
@mixin gradient($startColor, $endColor, $gradiend) {

  @if($gradiend == false) {
    background: $startColor;
  }
  @else {
    background: $startColor;
    background: linear-gradient(to top, $startColor, $endColor);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor,endColorstr=$endColor, GradientType=0 );
  }
}


/* Кнопки */
$isButtonGradient: false;                /* Градиент да/нет? Если нет, используется цвет 1 */
$isButtonShadow: false;                 /* Тень, да/нет          */
$isButtonBorder: false;                 /* Рамка, да/нет          */

$buttonBgColor1: $red;                 /* Цвет фона 1 (нижний)  */
$buttonBgColor2: $red;                 /* Цвет фона 2 (верхний) */
$buttonBorder: 1px solid #0c3658;       /* Рамка кнопок          */
$buttonShadow: 1px 2px 3px 0 #a8a8a8;   /* Тень у кнопок         */
$buttonColor: #fff;                     /* Цвет текста кнопок    */
$buttonBorderRadius: $borderRadius;     /* Закругления           */

$buttonBorderColor: #fff;

// наведение
$buttonBgColor1--hover: #c92f2f;
$buttonBgColor2--hover: #c92f2f;
$buttonColor--hover: #fff;
$buttonBorderColor--hover: #1b9fae;
$buttonShadow--hover: none;

// актив
$buttonBgColor1--active: $red - 30%;
$buttonBgColor2--active: $red - 30%;
$buttonColor--active: #fff;
$buttonBorderColor--active: #1b9fae;
$buttonShadow--active: none;



@mixin button() {

  @if($isButtonGradient == true) {
    background: linear-gradient(to top, $buttonBgColor1, $buttonBgColor2);

  }
  @else {
    background: $buttonBgColor1;
  }


  &.disabled {
    background: #999;
    cursor: default;
    pointer-events: none;
  }

  border-radius: 0;
  border: none;

  /* Стили кнопки по-умолчанию */
  display: inline-block;
  color: $buttonColor;
  cursor: pointer;
  font-size: 15px;
  outline: none;
  padding: 8px 45px 10px;
  pointer-events: all;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-out;
  user-select: none;
  white-space: nowrap;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;
  -moz-appearance: none;
  -webkit-appearance: none;

  @content;
}



/* Инпуты  */
$isInputBorder: false;                    /* Есть ли рамка */
$isInputShadow: false;                    /* Есть ли тень  */

$inputColor: #363636;                     /* Цвет текста   */
$inputBg: rgba(222,222,222,0.6);          /* Цвет фона     */
$inputBorderColor: #f2f2f2;               /* Цвет рамки    */
$inputShadow: inset 0 2px 7px 0 #a6a6a7;  /* Тень          */


@mixin input() {

  background: $inputBg;

  @if($isInputBorder == true) {
    border: 1px solid $inputBorderColor;
  }
  @else {
    border: none;
  }

  @if($isInputShadow) {
    box-shadow: $inputShadow;
  }

  border-radius: 0;
  color: $inputColor;
  display: block;
  font-size: 15px;
  height: 40px;
  padding: 8px 1em;
  outline: none;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  @content;

  &:focus {
    background: rgba(255,255,255,0.6);
    transition: 0.3s ease-out;
  }
}



/* Модули */
$moduleBg: #fff;                            /* Цвет фона модулей                      */
$moduleMobileHeaderBg: #c1c1c1;             /* Цвет фона заголовков модулей в мобайл  */
$moduleHeaderBg: $linkColor;                /* Цвет фона модулей                      */
$moduleHeaderColor: #fff;                   /* Цвет текста в шапке                    */
$moduleMobileHeaderColor: #2c2c2c;          /* Цвет текста в шапке                    */
$moduleBorder: 0;                           /* Рамка                                  */
$module_top__box-shadow: 0px 2px 3px #808080;

@mixin module($isMobile) {

  @if($isMobile == false) {
    background: $moduleBg;
  }

  margin: 1em 0 2em;

  @if($moduleBorder != 0) {
    border: $moduleBorder;
  }


  @content;
}


@mixin fontawesome($n) {

  &:before {
    @if($n != '') {
      content: $n;
    }
    @else {
      content: '';
    }

    color: $linkColor;
    font: normal 18px/18px "FontAwesome";
    pointer-events: none;
    position: absolute;
    margin: -9px 0 0 -9px;
    top: 50%;
    left: 50%;

    @content;

    z-index: 2;
  }
}


.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}
