/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    position: relative;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

    &:focus {
        outline: none;
    }
}


.slick-arrow {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    text-indent: -9999px;
    z-index: 3;

    &:hover {
        background: transparent;
        opacity: 1;
        transition: 0.3s ease-out;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        text-indent: 0;
    }

    &.slick-hidden {
        display: none;
    }
}


.slick-dots {
    & > li {
        & > button {
            background: transparent;
            border: none;
            outline: none;

            &:focus {
                outline: none;
            }
        }
    }
}


