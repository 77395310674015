/* Вид на мобильных девайсах */
.mobile {

  .menu-button {
    cursor: pointer;
    float: right;
    margin: 4px 0 0;
    outline: none;
    padding: 0 1em;
    position: relative;
    width: calc(36px + 2em);
    height: 36px;
    -webkit-tap-highlight-color: $webkitHighlightTapColor;
    user-select: none;


    & > span {
      display: block;
      position: absolute;
      height: 4px;
      width: 28px;
      background: $black;
      opacity: 1;
      left: 19px;
      transition: 0.1s ease-out;

      &:nth-child(1) {
        top: 9px;
      }
      &:nth-child(2) {
        top: 16px;
      }
      &:nth-child(3) {
        top: 23px;
      }
    }

    &.open {
      & > span {
        &:nth-child(1) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 25px;
        }
      }
    }

    @include media(sm) {
      margin: 0;
      padding: 0 0.5em 0 0;
      width: calc(36px + 0.5em);

      & > span {
        left: 5px;
      }
    }
  }
}


