.container {
  margin: 0 auto;
  max-width: $containerWidth;
  padding: 0 1em;
  width: 100%;

  @extend .clearfix;
}


main {
  background:
          url('/assets/images/bgt.jpg') left top no-repeat,
          url('/assets/images/bgb.jpg') right bottom no-repeat;
  overflow: hidden;
  min-height: calc(100vh - 437px);
  width: 100%;
}

aside {
  display: none;
}

.content-block {
  padding: 0 1em;

}
