.news-detail {
  margin: 1em 0 0;

  &__header {

  }

  &__title {
    font-weight: normal;
    line-height: 1.1;

    @include media(md) {
      font-size: 28px;
    }
  }

  &__info {
    color: $linkColor;
    font-size: 13px;
    margin: 0 0 2em;
    text-align: center;
  }

  &__author {
    color: $linkColor;
    font-size: 13px;
  }

  &__date {

  }

  &__text {
    @extend .clearfix;
    & > a {
      float: left;
      outline: none;
    }
  }


  &__image {
    display: block;
    margin: 0 1em 0 0;
  }


  &__tags {
    float: left;

    width: calc(100% - 240px);

    & > span {
      display: inline-block;

      & > a {
        color: $red;
        display: inline-block;
      }
    }
  }

  &__bottom {
    @extend .clearfix;
    
    .news-all {
      float: left;
    }

    .pluso {
      float: right;
    }
  }
}

.news-all {
  & > a {
    color: $red;
  }
}
