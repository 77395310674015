.input-text,
.input-textarea {
  @include input();
}

textarea {
  margin: 0 0 1em;
  height: 150px;
  display: block;
  resize: none;
  padding: 0.5em 1em;
  width: 100%;
}



form {

  .field {
    margin: 0 0 1em;
    position: relative;
    text-align: left;

    .form__label {
      color: $grey;
      font-size: 13px;
      position: absolute;
      top: 7px;
      left: 1em;
      pointer-events: none;
      line-height: 1.5;
      z-index: 2;
    }

    &.form_block__last {
      padding: 0;
      margin: -1em 0 0;

      .button {
        margin: 0.5em 0 0;
      }
    }

    &:not(.form_block__last) {

      .form_title {
        position: absolute;
        top: 4px;
        left: 0.75em;
        z-index: 10;
      }
    }
  }

  .required-message {
    font-size: 13px;
    margin: 0 0 1em;
  }
}


.has-error {

  & > label {
    color: $colorError;
  }

  & > .input-text,
  & > .input-textarea {
    border-color: $colorError !important;
    box-shadow: 0 0 2px $colorError;
  }
}


.has-success {

  & > .input-text,
  & > .input-textarea {
    border-color: $colorOk !important;
    box-shadow: 0 0 2px $colorOk;
  }
}


.form_content {
  position: relative;
}


.help-block {
  font-size: 11px;
  position: absolute;
  top: 5px;
  right: 1em;
  pointer-events: none;
  visibility: hidden;

  &.form-error {
    color: $colorError;
  }
}



.check-agree {

  .checkbox {
    &:not(checked) {
      & + label {
        &:before {
          top: 3px;
          left: 0;
          margin-top: 0;
        }

        &:after {
          top: 4px;
          left: 1px;
          margin-top: 0;
        }
      }
    }
  }



  label {
    cursor: pointer;
    font-size: 13px;
    display: inline-block;
    line-height: 1.333;

    &.has-error {
      color: $colorError;
    }
  }

  .input-form {
    margin: 0 0.5em 0 0;
    vertical-align: bottom;
    width: auto;
  }


}


