
.popup-block__fon {
  background: rgba(0,0,0,.5);
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  position: fixed;
}

.popup-block {
  background: #fff;
  box-shadow: 7px 10px 60px 0 #383838;
  border-radius: $borderRadius;
  display: none;
  font-size: 15px;
  width: 410px;
  margin: -100px 0 0 -205px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1010;

  &__content {
    margin: 1em 0 0;
    text-align: center;
  }


  .input-textarea {
    height: 8em;
  }

  &__inner {
    padding: 0.5em 1em 1em;
    position: relative;
  }


 &__title {
    color: #444;
    font: bold 20px/1.2 $fontName;
    text-transform: uppercase;
  }


  @include media(sm) {

    &__content {
      margin: 0.5em 0 0;
    }

    &__title {
      font-size: 14px;
    }

    .field {
      &.check-agree {
        & > label {
          font-size: 9px;
          line-height: 1.4;
        }
      }
    }
  }
}


.popup-block__cross {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 40px;
  will-change: transform;
  z-index: 2;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;

  &:before {
    content: '\f2d3';
    display: block;
    font: normal 29px/1 "FontAwesome";
    color: $linkColor--hover;
    position: relative;
    top: 3px;
  }

  &:hover {
    &:before {
      color: $linkColor;
      transition: color 0.3s ease;
    }
  }

}


.popup-block {

  &.reg-window {
    margin: -96px 0 0 -224px;
    width: 448px;

    .reg_auth-section {

      .addition-block {
        float: right;
        margin: 8px 0 0;
        text-align: right;

        & > a {
          display: block;
          font-size: 13px;
          line-height: 1.4;
          margin: 0 0.5em;
          -webkit-tap-highlight-color: transparent;

          & > span {
            -webkit-tap-highlight-color: $webkitHighlightTapColor;
          }
        }
      }

      .form_block {

        &.form_block__last {
          float: left;
          padding: 0.5em 0 0;
          width: 100px;

          & > .form_title {
            font-size: 11px;
            top: -16px;
          }
        }
      }
    }


    @include media(sm) {
      width: calc(320px - 2em);
      margin: -90px 0 0 calc(160px - 320px + 1em);


      .popup-block__title {
        padding: 0 1.5em;
        margin: 0 0 0.5em;
      }

      .addition-block {
        float: right;
        margin: -5px 0 0;
        text-align: right;
        width: 120px;

        & > a {
          font-size: 13px;
          display: inline-block;
        }
      }
    }
  }
}

.popup-block {
  &.auth-ok {
    margin-top: -3em;
  }
}


.popup-block {
  &.auth-error {
    margin-top: -3em;
  }
}

.popup-block {
  &.order-call {
    margin-top: -172px;

    @include media(sm) {
      max-width: calc(320px - 2em);
      margin: -12em 0 0 calc(160px - 320px + 1em);
      width: 100%;
    }
  }
}


.popup-block {
  &.sendformok {
    margin-top: -3em;
  }
}


.popup-block {
  &.callbackformok {
    margin-top: -3em;
  }
}




.product-popup__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 2px;
  padding: 6px 6px;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;

  &:before {
    content: '\f2d3';
    display: block;
    font: normal 29px/1 "FontAwesome";
    color: $linkColor--hover;
    position: relative;
    top: 0;
  }

  &:hover {
    &:before {
      color: $linkColor;
      transition: color 0.3s ease;
    }
  }
}


