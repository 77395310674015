@import "vars";
@import "media";

/* Импорт sass модулей, по порядку */
/* Неиспользуемые модули надо закомментировать, используемые - раскомментировать */
@import "system/normalize";
@import "system/preloader";
               /* Медиа миксины. Как использовать см. внутри */
@import "system/fonts";        /* подгрузка шрифтов */
@import "system/icon";
@import "layout";              /* Основной склелет */
@import "styles";              /* Основные стили */
@import "system/forms";
@import "header/header";
@import "header/headermobile";

@import "slider";
@import "slick/slick";
//@import "slick/slick-theme";

@import "menu/menu_top";

@import "menu/mobile_menu";
@import "system/feedback";
@import "footer";

@import "system/popups";
@import "opros/opros";
@import "search";
@import "photo/photos";


@import "system/modules";

@import "news/news";
@import "news/newsfull";
@import "news/navigation";
@import "news/news-cats";

@import "system/gallery";