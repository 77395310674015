
.news-categories {
  @extend .clearfix;

  &__item {
    float: left;
    margin: 0 1em 1em 0;
    width: calc(33.333% - 0.75em);

    &:nth-child(3n + 3) {
      margin: 0 0 1em;
    }
  }

  &__image {
    @extend .img-responsive;
  }
}

