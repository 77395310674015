@font-face {
  font-family: 'FontAwesome';
  src: url('/assets/fonts/fontawesome/fontawesome-webfont.eot?v=4.7.0');
  src: url('/assets/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
  url('/assets/fonts/fontawesome/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
  url('/assets/fonts/fontawesome/fontawesome-webfont.woff?v=4.7.0') format('woff'),
  url('/assets/fonts/fontawesome/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
  url('/assets/fonts/fontawesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI';
  src: url('/assets/fonts/segoeuisemib/SegoeUI-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/segoeuisemib/SegoeUI-SemiBold.woff') format('woff'),
  url('/assets/fonts/segoeuisemib/SegoeUI-SemiBold.ttf')  format('truetype'),
  url('/assets/fonts/segoeuisemib/SegoeUI-SemiBold.svg#SegoeUI-SemiBold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI';
  src: url('/assets/fonts/segoeuireg/SegoeUI.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/segoeuireg/SegoeUI.woff') format('woff'),
  url('/assets/fonts/segoeuireg/SegoeUI.ttf') format('truetype'),
  url('/assets/fonts/segoeuireg/SegoeUI.svg#SegoeUI') format('svg');
  font-weight: normal;
  font-style: normal;
}
