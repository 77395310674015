.mobile {

  nav {

    .container {
      padding: 0;
    }

    .arrow {
      height: 38px;
      outline: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 38px;
      transition: transform 0.3s ease;
      transform: translate3d(0,0,0);
      -webkit-tap-highlight-color: $webkitHighlightTapColor;
      will-change: transform;
      z-index: 10;

      & > span {
        &:before {
          transition: 0.3s ease-out;
        }
      }
    }

    .menu_level_1 {
      display: block;

      & > li {
        display: block;
        text-align: left;
        width: auto;

        &.active {

        }

        &.has_child {
          & > a {
            width: calc(100% - 38px);
          }
        }

        &.open {
          .arrow {
            //top: calc(100% - 38px);

            &:before {
              color: $white !important;
              transform: rotate(180deg);
            }
          }
        }

        & > a {
          color: $white;
          display: block;
          padding: 8px 1em 9px;
          text-align: left;
        }

        .arrow {
          margin: 0;
          pointer-events: all;
          
          &:before {
            color: $linkColor;
            font-size: 20px;
            margin: -8px 0 0 -6px;
          }
        }

        &.active {
          & > a {
            color: $linkColor--hover;
          }
        }

        &:not(.active) {
          & > a {
            &:hover {
              color: $linkColor--hover;
            }
          }
        }
      }
    }

    .menu_level_2 {
      background: rgba(0,0,0,.1);
      box-shadow: none;
      display: none;
      position: static;
      overflow: hidden;
      opacity: 1;
      list-style: none;
      visibility: visible;

      & > li {
        display: block;
        padding: 0;

        & > a {
          padding: 6px 1em 9px 2.5em;
          color: $bgColor;
          font-size: 12px;
        }

        &.active {
          
          & > a {
            color: $linkColor--hover;
          }
          
          &.has_child {
            .menu_level_3 {
              display: block;
            }
          }
        }

        &:not(.active) {
          & > a {
            &:hover {
              color: $linkColor--hover;
            }
          }
        }
      }


      .mob-icon {
        display: none;
      }
    }

    
    .menu_level_3 {
      background: rgba(55,55,55,0.7);
      display: none;
      
      & > li {
        display: block;
        padding: 0;

        & > a {
          padding: 0.5em 1em 0.6em 3em;
          color: $bgColor;
          font-size: 13px;
        }

        &.active {
          & > a {
            color: $linkColor--hover;
          }
        }

        &:not(.active) {
          & > a {
            &:hover {
              color: $linkColor--hover;
            }
          }
        }
      }
    }
  }
}


.mobile {
  &.menu-visible {
    nav {
      transition: transform 0.3s ease-out;
      transform: translate3d(0,0,0);
      visibility: visible;
    }
  }
}
